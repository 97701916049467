<template>
  <div>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-row align="center" justify="center">
        <v-col cols="8">
            <base-material-card
                icon="mdi-account-plus"
                title="Vendor Emails"
                class="px-5 py-3"
            >

                <v-data-table
                :headers="StaffHeaders"
                :items="vendor_email"
                disable-pagination
                :hide-default-footer="true"
                >
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-spacer></v-spacer>
                    <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" > New Vendor</v-btn>
                      </template>
                      <v-card>
                          <v-card-title>
                            <span class="card-title font-weight-light">New Vendor</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                                <v-row>
                                  <v-col
                                    cols="12"
                                    md="6"
                                  >
                                    <v-text-field
                                      v-model="selected_vendor.Vendor"
                                      label="Vendor"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="6"
                                  >
                                    <v-text-field
                                      v-model="selected_vendor.Name"
                                      label="Name"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="6"
                                  >
                                    <v-text-field
                                      v-model="selected_vendor.Email"
                                      label="Email"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="save"
                            >
                              Save
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title >Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.ModifiedDate`]="{ item }">
                  <span>{{ new Date(item.ModifiedDate).toDateString().split(' ').splice(1,3).join(" ")}}</span>
                </template>
                <template v-slot:[`item.vendor_email`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="view_breakdown(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </base-material-card>
        </v-col>
    </v-row>
  </v-container>
  </div>
</template>
<script>
import axios from 'axios';
export default {
    name:'VendorEmail',
    data () {
      return {
        url:process.env.VUE_APP_URL,
        StaffHeaders: [
            {
            text: 'Vendor',
            sortable: false,
            class:"primary--text",
            value: 'Vendor',
            },
            {
            text: 'Name',
            value: 'Name',
            sortable: false,
            class:"primary--text",
            },
            {
            text: 'Email',
            value: 'Email',
            sortable: false,
            class:"primary--text",
            },
            {
            text: 'Modified Date',
            value: 'ModifiedDate',
            sortable: false,
            class:"primary--text",
            },
            {
            text: 'Edit',
            value: 'vendor_email',
            align: 'end',
            sortable: false,
            class:"primary--text",
            }
        ],
        dialog: false,
        dialogDelete: false,
        editedIndex:  -1,
        valid: false,
        vendor_email: [],
        selected_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        default_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      permissions(){
        return {
          scope: localStorage.getItem("permissions")
        }
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.initialize()
    },
    methods: {

        initialize () {
          axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL,
          {
            // params: {
            // }
          }
          )
          .then(response => {
            this.vendor_email = response.data
          })
          .catch(error => {
            console.log(error)
          })
        },
        view_breakdown (vendor_email) {
          this.editedIndex = this.vendor_email.indexOf(vendor_email)
          this.selected_vendor = Object.assign({}, vendor_email)
          this.dialog = true
        },
        close () {
          this.dialog = false
          this.$nextTick(() => {
            this.selected_vendor = Object.assign({}, this.default_vendor)
            this.editedIndex = -1
          })
        },
        save () {
          var body ={
              "item_id": this.selected_vendor.ItemId,
              "vendor":this.selected_vendor.Vendor,
              "name": this.selected_vendor.Name,
              "email":this.selected_vendor.Email
            }
          if (this.editedIndex > -1) {
            axios
            .post(process.env.VUE_APP_UPDATE_VENDOR_EMAIL_URL,body)
            .then(() => {
            })
            .catch(error => {
              console.log(error)
            })
            Object.assign(this.vendor_email[this.editedIndex], this.selected_vendor)
          } else {
            axios
            .post(process.env.VUE_APP_INSERT_VENDOR_EMAIL_URL,body)
            .then(() => {
              this.initialize()
            })
            .catch(error => {
              console.log(error)
            })
          }
          this.selected_vendor = Object.assign({}, this.default_vendor)
          this.editedIndex = -1
          this.close()
        },
        deleteItem (vendor_email) {
          this.editedIndex = this.vendor_email.indexOf(vendor_email)
          this.selected_vendor = Object.assign({}, vendor_email)
          this.dialogDelete = true
        },
        deleteItemConfirm () {
          var item = this.vendor_email[this.editedIndex]
          var body = {"item_id":item.ItemId}
          axios
          .post(process.env.VUE_APP_DELETE_VENDOR_EMAIL_URL,body)
          .then(response => {
            console.log(response.data)
            this.initialize()
          })
          .catch(error => {
            console.log(error)
          })
          // this.vendor_email.splice(this.editedIndex, 1)
          this.closeDelete()
          },
        closeDelete () {
          this.dialogDelete = false
          this.$nextTick(() => {
            this.selected_vendor = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
    },
}
</script>
<style lang="scss" scoped>
</style>